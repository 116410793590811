import { range } from 'ramda';
import { gsap } from 'gsap';
import { ScrollTrigger, Back, Linear } from 'gsap/all';

import './vanilla-title';

gsap.registerPlugin(ScrollTrigger);

export function getGsap() {
    return gsap;
}

export function getScrollTrigger() {
    return ScrollTrigger;
}

/**
 * @description 基于gsap ScrollTrigger实现的视频自动播放
 * @param selector <video>标签的CSS选择器
 * @param start 开始播放的触发点，参考gsap文档
 * @param end 暂停播放的触发点，参考gsap文档
 */
export function videoAutoPlay(selector: string, start?: string, end?: string): void {
    const scrollTriggerList: Array<ScrollTrigger> = [];
    // 视频 播放
    const videoPlay = (videoElmt: HTMLVideoElement) => {
        if (videoElmt && videoElmt.paused) {
            videoElmt.play().catch(function (err) {
                console.log(err);
                // console.log("不允许自动播放");
            });
        }
    };

    // 视频 暂停
    const videoPause = (videoElmt: HTMLVideoElement) => {
        if (videoElmt && !videoElmt.paused) {
            videoElmt.pause();
        }
    };

    const init = (videoDom) => {
        if (videoDom.tagName === 'VIDEO') {
            scrollTriggerList.push(
                ScrollTrigger.create({
                    // markers: { startColor: 'green', endColor: 'red', fontSize: '12px' },
                    trigger: videoDom,
                    start: start || 'top center',
                    end: end || 'bottom center',
                    onEnter: () => videoPlay(videoDom), // 视频元素离开页面开始播放
                    onEnterBack: () => videoPlay(videoDom),
                    onLeave: () => videoPause(videoDom), // 视频元素离开页面暂停播放
                    onLeaveBack: () => videoPause(videoDom),
                }),
            );
        }
    };

    const videoDomList: Array<HTMLVideoElement> = gsap.utils.toArray(selector);
    videoDomList.forEach((videoDom) => {
        // 初始化
        init(videoDom);
    });
}

/**
 * @description 为页面中的<video>对象添加事件：出现在屏幕中自动播放，离开屏幕自动暂停
 * @param selector <video>标签的CSS选择器
 */
export function addToVideoElmt(selector: string): void {
    onMounted(() => {
        videoAutoPlay(selector, 'top bottom', 'bottom top');
        // 视频逐帧播放（实现效果不佳）
        /* 
        let videoDomList: Array<HTMLVideoElement> = gsap.utils.toArray(".home-details-media-video");
        videoDomList.forEach(videoDom => {
            ScrollTrigger.create({
                // markers: { startColor: "green", endColor: "red", fontSize: "12px" },
                trigger: videoDom,
                start: "top 90%",
                end: "bottom top",
                onUpdate: (self) => {
                    setVideoProgress('#home-details-media-video-0', self.progress.toFixed(3), self.direction)
                    console.log("进度:", self.progress.toFixed(3), "朝向:", self.direction, "滚动速度", self.getVelocity());
                }
            })
        });
    
        function setVideoProgress(elem, progress, direction) {
            console.log(progress, direction)
            let videoEl = document.querySelector(elem)
            let videoTime = 8; //引入视频的时间，此完整视频全长为3s
            videoEl.currentTime = progress * videoTime
        }
    
        */
    });
}

const queryDom = (targets: string | Array<HTMLElement>) => {
    const scrollTriggerList =
        typeof targets === 'string' ? (gsap.utils.toArray(targets) as Array<HTMLElement>) : targets;
    // console.log(scrollTriggerList);
    return scrollTriggerList;
};

export function addLeftEnter(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    trigger?: string,
): Array<ScrollTrigger> {
    gsap.set(targets, {
        opacity: 0,
    });
    return queryDom(targets).map((element) => {
        return ScrollTrigger.create({
            trigger: trigger || element,
            start: 'top bottom',
            onEnter: () => {
                gsap.fromTo(
                    element,
                    { xPercent: -50, opacity: 0 },
                    {
                        xPercent: 0,
                        opacity: 1,
                        ease: 'circ.out',
                        ...vars,
                    },
                );
            },
            onLeaveBack: () => {
                gsap.to(element, {
                    opacity: 0,
                });
            },
        });
    });
}

export function addRightEnter(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    trigger?: string,
): Array<ScrollTrigger> {
    gsap.set(targets, {
        opacity: 0,
    });
    return queryDom(targets).map((element) => {
        return ScrollTrigger.create({
            trigger: trigger || element,
            start: 'top bottom',
            onEnter: () => {
                gsap.fromTo(
                    element,
                    { xPercent: 50, opacity: 0 },
                    {
                        xPercent: 0,
                        opacity: 1,
                        ease: 'circ.out',
                        ...vars,
                    },
                );
            },
            onLeaveBack: () => {
                gsap.to(element, {
                    opacity: 0,
                });
            },
        });
    });
}

export function addZoomIn(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    trigger?: string,
): Array<ScrollTrigger> {
    gsap.set(targets, {
        opacity: 0,
    });
    return queryDom(targets).map((element) => {
        return ScrollTrigger.create({
            trigger: trigger || element,
            start: 'top bottom',
            onEnter: () => {
                gsap.fromTo(
                    element,
                    { scale: 5, opacity: 0 },
                    {
                        scale: 1,
                        opacity: 1,
                        ease: 'slow(0.7, 0.7, false)',
                        duration: 0.6,
                        ...vars,
                    },
                );
            },
            onLeaveBack: () => {
                gsap.to(element, {
                    opacity: 0,
                });
            },
        });
    });
}

export function addFadeIn(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    trigger?: string,
): Array<ScrollTrigger> {
    gsap.set(targets, {
        opacity: 0,
    });
    return queryDom(targets).map((element) => {
        return ScrollTrigger.create({
            trigger: trigger || element,
            start: 'top bottom',
            onEnter: () => {
                gsap.fromTo(
                    element,
                    { opacity: 0 },
                    {
                        opacity: 1,
                        duration: 2,
                        ease: 'slow(0.7, 0.7, false)',
                        ...vars,
                    },
                );
            },
            onLeaveBack: () => {
                gsap.to(element, {
                    opacity: 0,
                });
            },
        });
    });
}

export function addSlideInUp(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    trigger?: string,
): Array<ScrollTrigger> {
    gsap.set(targets, {
        opacity: 0,
    });
    return queryDom(targets).map((element) => {
        return ScrollTrigger.create({
            trigger: trigger || element,
            start: 'top bottom',
            onEnter: () => {
                gsap.fromTo(
                    element,
                    { y: 200, opacity: 0 },
                    {
                        // scale: 100,
                        y: 0,
                        opacity: 1,
                        duration: 0.6,
                        ease: 'slow(0.7, 0.7, false)',
                        ...vars,
                    },
                );
            },
            onLeaveBack: () => {
                gsap.to(element, {
                    opacity: 0,
                });
            },
        });
    });
}

export function addSlideInDown(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    trigger?: string,
): Array<ScrollTrigger> {
    gsap.set(targets, {
        opacity: 0,
    });
    return queryDom(targets).map((element) => {
        return ScrollTrigger.create({
            trigger: trigger || element,
            start: 'top bottom',
            onEnter: () => {
                gsap.fromTo(
                    element,
                    { y: -100, opacity: 0 },
                    {
                        // scale: 100,
                        y: 0,
                        opacity: 1,
                        duration: 0.6,
                        ease: 'slow(0.7, 0.7, false)',
                        ...vars,
                    },
                );
            },
            onLeaveBack: () => {
                gsap.to(element, {
                    opacity: 0,
                });
            },
        });
    });
}
// 从小到大 + 旋转
export function addMagnifyRotate(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    trigger?: string,
): Array<ScrollTrigger> {
    gsap.set(targets, {
        opacity: 0,
    });
    return queryDom(targets).map((element) => {
        return ScrollTrigger.create({
            trigger: trigger || element,
            start: 'top bottom',
            onEnter: () => {
                gsap.fromTo(
                    element,
                    { opacity: 0, scale: 0, rotate: 90 },
                    {
                        scale: 1,
                        rotate: 0,
                        opacity: 1,
                        duration: 0.6,
                        ease: 'slow(0.7, 0.7, false)',
                        ...vars,
                    },
                );
            },
            onLeaveBack: () => {
                gsap.to(element, {
                    opacity: 0,
                });
            },
        });
    });
}

// 文字逐个渐进
function addTextGradual(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    // trigger?: string,
): Array<ScrollTrigger> {
    return queryDom(targets).map((element) => {
        const itemText = element.innerHTML;
        element.innerHTML = '';
        element.innerHTML += '<span>' + itemText + '</span>';
        const spanFillMask = element.querySelector('span');
        // console.log('span中的内容',spanFillMask);
        return ScrollTrigger.create({
            // markers: { startColor: 'green', endColor: 'red', fontSize: '12px' },
            trigger: spanFillMask,
            start: 'top 85%',
            end: () => `+=${element.offsetHeight * 2}`,
            scrub: 1,

            // onEnter: () => {
            animation: gsap.fromTo(
                spanFillMask,
                {
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '0% 100%',
                    // backgroundImage: 'linear-gradient( #fff, #fff)',
                    backgroundImage:
                        'linear-gradient(75deg,rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33.3%,rgba(255,255,255,0) 66.7%,rgba(255,255,255,0) 100%)', // 上层
                    // '-webkit-text-fill-color': 'rgba(255,255,255,0.1)',//or ⬇
                    webkitTextFillColor: 'rgba(255,255,255,0.1)',
                    '-webkit-background-clip': 'text',
                    ...vars,
                },
                {
                    // duration: 1,
                    backgroundSize: '300% 100%',
                    // opacity: 1,
                    stagger: 0.5,
                },
            ),
            // },
        });
    });
}

// 文字透明出现
function addTextOpacity(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    // trigger?: string,
): Array<ScrollTrigger> {
    return queryDom(targets).map((element) => {
        function wrapCharactersWithSpan(element: Node) {
            if (element.nodeType === Node.TEXT_NODE) {
                const text = element.textContent as string;
                const fragment = document.createDocumentFragment();
                for (let i = 0; i < text.length; i++) {
                    const span = document.createElement('span');
                    span.textContent = text[i];
                    fragment.appendChild(span);
                }
                element.parentNode!.replaceChild(fragment, element);
            } else if (element.nodeType === Node.ELEMENT_NODE) {
                const childNodes = Array.from(element.childNodes);
                childNodes.forEach((child) => wrapCharactersWithSpan(child as Node));
            }
        }
        const container = document.createElement('div');
        // console.log(element.innerHTML);
        container.innerHTML = element.innerHTML; // `"${element}"`;
        // console.log(container.innerHTML);

        wrapCharactersWithSpan(container);
        const result = container.innerHTML;
        element.innerHTML = result;

        // const itemText = element.innerText;
        // let result = '';
        // for (let i = 0; i < itemText.length; i++) {
        //     result += `<span>${itemText.charAt(i)}</span>`;
        //     element.innerHTML = result;
        // }
        const spanFillMask = element.querySelectorAll('span');
        // console.log(spanFillMask);

        return ScrollTrigger.create({
            // markers: { startColor: 'green', endColor: 'red', fontSize: '12px' },
            trigger: spanFillMask,
            start: 'top 85%',
            end: () => `+=${element.offsetHeight}`, //
            scrub: 1,

            // onEnter: () => {
            animation: gsap.fromTo(
                spanFillMask,
                {
                    opacity: 0.2,
                    ...vars,
                },
                {
                    duration: 1,
                    backgroundSize: '300% 100%',
                    opacity: 1,
                    stagger: 0.5,
                    ease: Linear.easeNone,
                },
            ),
            // },
        });
    });
}

// 对于文字特效 addTextGradual  ios不支持部分样式 遂换一种addTextOpacity
// 对于复用组件内使用该方法，必须使用ref中的value来绑定，否则onMounted会执行多次（该页面组件被复用的次数），在addTextOpacity方法中也会多次嵌套span，不利于加载
export function addTextScrollLine(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    varsIos?: gsap.TweenVars,
) {
    if (isAndroid() === 'ios') {
        // 记得改成ios
        addTextOpacity(targets, varsIos);
    } else {
        addTextGradual(targets, vars);
    }
}

// 跳动文字
export function addTextBounce(
    targets: string | Array<HTMLElement>,
    vars?: gsap.TweenVars,
    // trigger?: string,
): Array<ScrollTrigger> {
    return queryDom(targets).map((element) => {
        // console.log(element);

        const itemText = element.innerText;
        let result = '';
        for (let i = 0; i < itemText.length; i++) {
            result += `<span>${itemText.charAt(i)}</span>`;
            element.innerHTML = result;
        }
        const spanFillMask = element.querySelectorAll('span');
        // console.log(spanFillMask);

        return ScrollTrigger.create({
            // markers: { startColor: 'green', endColor: 'red', fontSize: '12px' },
            trigger: element,
            start: 'top-=30% center',
            end: '70% 80%',
            animation: gsap.fromTo(
                spanFillMask,
                { ease: Back.easeInOut.config(1.7), opacity: 0, y: -100 },
                { ease: Back.easeInOut.config(1.7), opacity: 1, y: 0, stagger: 0.04, duration: 0.5, ...vars },
            ),
        });
    });
}

/**
 * @description 卡片滑动升级版本，对响应式更加友好，代码量少
 * @param item 卡片的选择器
 * @param visiable  卡片可视部分的父容器，一般设置position: relative;overflow: hidden;
 * @param container 卡片的容器：宽度大于
 * @param pinContainer 可选值如 'body' | container | 自定义
 */
export function addCardScrollHorizontalUpgrade(
    item: string,
    visible: string,
    container: string,
    pinContainer: string,
    vars?: ScrollTrigger.StaticVars,
): void {
    const cardScrollHeight = 400;
    const cardItemDomList: Array<HTMLElement> = gsap.utils.toArray(item);
    // 可视部分宽带
    const visibleWidth = (document.querySelector(visible) as HTMLElement).scrollWidth;
    // 内部元素滚动宽度
    const containerWidth = (document.querySelector(container) as HTMLElement).scrollWidth;

    if (containerWidth >= visibleWidth) {
        ScrollTrigger.create({
            ...vars,
            trigger: visible,
            start: 'center center',
            end: `+=${cardScrollHeight * cardItemDomList.length} center`,
            scrub: true,
            pin: pinContainer === null ? container : pinContainer,
            animation: gsap.timeline({}).to(container, { xPercent: -100, left: '100%' }),
        });
    }
}

/**
 * @description 添加如首页解决方案中的 卡片堆叠+滚轮翻阅 效果
 * @param parent 卡片container的CSS选择器
 * @param cardSelector 卡片card的CSS选择器
 * @param tx 每张卡片在x，z，y轴上的偏移程度，形成堆叠
 * @param scrollHeightPerCard 翻动一张卡片在页面上所需要的滚动高度
 * @param leavePosition 卡片离开位置，参考gsap vars
 */
export function addCardStacking(
    parent: string,
    cardSelector: string,
    params: {
        tx: number;
        ty: number;
        tz: number;
        scrollHeightPerCard: number;
        leavePosition: object; // 卡片离开位置
    },
) {
    const { tx, ty, tz, scrollHeightPerCard, leavePosition } = params;
    const cardNodes = gsap.utils.toArray(cardSelector) as HTMLElement[];
    cardNodes.forEach((cardItem, index) => {
        gsap.set(cardItem, { transform: `translate3d(${tx * index}px,${ty * index}px,  -${tz * index}px)` });
    });
    const tl = gsap.timeline({
        scrollTrigger: {
            // markers: { startColor: "green", endColor: "red", fontSize: "12px" },
            trigger: parent,
            start: 'center center',
            end: `+=${cardNodes.length * scrollHeightPerCard}`,
            scrub: true,
            // snap: Array.from(new Array(cardNodes.length).keys()).map((i) => i / cardNodes.length),
            pin: true,
            pinSpacing: true,
        },
    });
    range(0, cardNodes.length - 1).forEach((i) => {
        tl.to(`${cardSelector}:nth-child(${i + 1})`, {
            ...leavePosition,
            ease: 'slow(0.7, 0.7, false)',
            duration: 2,
        });
        range(i + 1, cardNodes.length).forEach((j, jIndex) => {
            tl.to(
                `${cardSelector}:nth-child(${j + 1})`,
                {
                    transform: `translate3d(${tx * jIndex}px,${ty * jIndex}px,  -${tz * jIndex}px)`,
                    duration: 4,
                },
                '<',
            );
        });
    });
    return tl;
}
/**
 * @description 卡片抖动
 * @param targets 卡片的选择器
 */
export function addCardJitter(targets: string): void {
    return VanillaTilt.init(document.querySelectorAll(targets), {
        max: 15, // 最大倾斜度数
        speed: 400, // 倾斜转换的速度
        glare: true, // 是否开启炫光
        'max-glare': 0, // 最大炫光的不透明度
    });
}
