// 判断是否是安卓系统
export function isAndroid() {
    if (/android/i.test(navigator.userAgent)) {
        // console.log('Android'); // Android
        // alert('Android');
        return 'Android';
    } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // console.log('iOS'); // iOS
        // alert('ios');
        return 'ios';
    } else {
        // alert('pc');
        return 'pc';
    }
}
